import { Request } from '@/http/request'

// 查询通信账户集合
export function getAmountList(parameter: any){
  return Request.axiosInstance.post('/open/amount/query-name', parameter)
}

//语音统计
export function riskCount(parameter: any){
  return Request.axiosInstance.post('/open/voice-count/risk-count', parameter)
}
//小号调用数据
export function numberbindcount(parameter: any){
  return Request.axiosInstance.post('/open/user-number-count/numberbindcount', parameter)
}



export default {numberbindcount, getAmountList,riskCount }