// json转数组
// export function adaper(json){
// 	var newArr = [];
// 	var map = {
// 		"a": 0,
// 		"b": 1,
// 		"c": 2
// 	}
// 	for(var i in map){
// 		newArr[map[i]] = json[i]
// 	}
// 	return newArr
// }

export function getDate1(dates) {
    var dd = new Date();
    var n = dates || 0;
    dd.setDate(dd.getDate() + n);
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1;
    var d = dd.getDate();
    m = m < 10 ? "0" + m: m;
    d = d < 10 ? "0" + d: d;
    var day = y + "-" + m + "-" + d+' 00:00:00';
    return day;
};
export function getDate2(dates) {
  var dd = new Date();
  var n = dates || 0;
  dd.setDate(dd.getDate() + n);
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1;
  var d = dd.getDate();
  m = m < 10 ? "0" + m: m;
  d = d < 10 ? "0" + d: d;
  var day = y + "-" + m + "-" + d+' 23:59:59';
  return day;
};
export function getDate(dates) {
  var dd = new Date();
  var n = dates || 0;
  dd.setDate(dd.getDate() + n);
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1;
  var d = dd.getDate();
  m = m < 10 ? "0" + m: m;
  d = d < 10 ? "0" + d: d;
  var day = y + "-" + m + "-" + d;
  return day;
};
export function dataFomart(value) {
	let date = new Date(value) //实例化时间对象
	let year = date.getFullYear()
	let month = date.getMonth() + 1
	let day = date.getDate()
	let months = '' + month
	let days = '' + day
	if (month < 10) {
	  months = '0' + months
	}
	if (day < 10) {
	  days = '0' + days
	}
	return `${year}-${months}-${days}`
  }
export function defaultTime() {
	let end = new Date()
	let start = new Date()
	start.setHours(0, 0, 0, 0)
	end.setTime(start.getTime() + 3600 * 1000 * 24 + 1)
	start.setTime(end.getTime() - 3600 * 1000 * 24 * 7 )
	let value = [dataFomart(start), dataFomart(end)]
	return value
  }
 
// getDate(dates)  //dates为数字类型，0代表今日,-1代表昨日，1代表明日，返回yyyy-mm-dd格式字符串，dates不传默认代表今日。


export function getMonday(type, dates) {
    var now = new Date();
    var nowTime = now.getTime();
    var day = now.getDay();
    var longTime = 24 * 60 * 60 * 1000;
    var n = longTime * 7 * (dates || 0);
    if (type == "s") {
        var dd = nowTime - (day - 1) * longTime + n;
    };
    if (type == "e") {
        var dd = nowTime + (7 - day) * longTime + n;
    };
    dd = new Date(dd);
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1;
    var d = dd.getDate();
    m = m < 10 ? "0" + m: m;
    d = d < 10 ? "0" + d: d;
    var day = y + "-" + m + "-" + d;
    return day;
};
 
// getMonday(type,dates)  //type为字符串类型，有两种选择，"s"代表开始,"e"代表结束，dates为数字类型，不传或0代表本周，-1代表上周，1代表下周
// getMonday("s",1)  //得到下周一的yyyy-mm-dd格式日期
// getMonday("e",1)  //得到下周日的yyyy-mm-dd格式日期

export function getMonth(type, months) {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    if (Math.abs(months) > 12) {
        months = months % 12;
    };
    if (months != 0) {
        if (month + months > 12) {
            year++;
            month = (month + months) % 12;
        } else if (month + months < 1) {
            year--;
            month = 12 + month + months;
        } else {
            month = month + months;
        };
    };
    month = month < 10 ? "0" + month: month;
    var date = d.getDate();
    var firstday = year + "-" + month + "-" + "01";
    var lastday = "";
    if (month == "01" || month == "03" || month == "05" || month == "07" || month == "08" || month == "10" || month == "12") {
        lastday = year + "-" + month + "-" + 31;
    } else if (month == "02") {
        if ((year % 4 == 0 && year % 100 != 0) || (year % 100 == 0 && year % 400 == 0)) {
            lastday = year + "-" + month + "-" + 29;
        } else {
            lastday = year + "-" + month + "-" + 28;
        };
    } else {
        lastday = year + "-" + month + "-" + 30;
    };
    var day = "";
    if (type == "s") {
        day = firstday;
    } else {
        day = lastday;
    };
    return day;
};
 
// getMonth(type,months)  //type为字符串类型，有两种选择，"s"代表开始,"e"代表结束，months为数字类型，不传或0代表本月，-1代表上月，1代表下月
// getMonth("s",1)  //得到下月第一天的yyyy-mm-dd格式日期
// getMonth("e",1)  //得到下月最后一天的yyyy-mm-dd格式日期
