import { Request } from "@/http/request"

// 系统号码绑定 
export function interfaceBind(parameter: any){
  return Request.axiosInstance.post('/open/call/bind',parameter)
}

// 系统号码解绑
export function interfaceUnbound(parameter: any){
  return Request.axiosInstance.post('/open/call/unbind',parameter)
}

export default { interfaceBind,interfaceUnbound }